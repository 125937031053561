import React, { useEffect, useState, } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { ButtonPrimary } from '../../components/Button'
//import { TYPE } from '../../theme'
import { useCruContract, useClaimContract, } from '../../hooks/useContract'
import { useCsmClaimCallback } from '../../state/claim/hooks'
import { useSingleCallResult } from '../../state/multicall/hooks'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import csmLogo from '../../assets/images/csm.png'
import warnIcon from '../../assets/images/icon_warn.png'
import errorIcon from '../../assets/images/icon_error.png'
import infoIcon from '../../assets/images/icon_info.png'
import successIcon from '../../assets/images/icon_success.png'
import bgLeft from '../../assets/images/csm_bg_left.png'
import bgRight from '../../assets/images/csm_bg_right.png'
import { ToastType, MessagesType} from './messages'
import { useLang } from './useLang'

const axios = require('axios').default
const queryString = require('query-string')

const Container = styled.div`
    width: 100%;
    height: 100%;
`

const BgLeft = styled.div`
    position: fixed;
    left: 0;
    width: 319px;
    height: 100%;
    background-image: url(${bgLeft});
    background-size: cover;
    background-repeat: no-repeat;
    @media only screen and (max-width: 720px) {
      display: none;
    }
`

const BgRight= styled.div`
    position: fixed;
    right: 0;
    width: 319px;
    height: 100%;
    background-image: url(${bgRight});
    background-size: cover;
    background-repeat: no-repeat;
    @media only screen and (max-width: 720px) {
    display: none;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 56px;
    @media only screen and (max-width: 720px) {
      margin-top: 15px;
    }
`

const ErrorMessage = styled.div<{visible: boolean}>`
  display: flex;
  position: fixed;
  top: 70px;
  z-index: 1000;
  flex-direction: row;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  height: 36px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 0px 4px 1px #24334C;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #666F83;
  line-height: 20px;
  padding: 9px 15px 9px 15px;
  margin-bottom: 9px;
`

const MessageIcon = styled.img`
   width: 20px;
   height: 20px;
   margin-right: 3px;
`

const Step = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-bottom: 4em;

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 0.5rem 1rem;
   `};
`

const StepPreface = styled(Step)`
    margin-bottom: 2em;
`;

const StepTitle = styled.div`
    text-align: center;
    width: 100%;
    height: 45px;
    font-size: 32px;
    font-weight: 600;
    color: #C5CAD5;
    line-height: 45px;`

const StepDesc = styled.div`
    margin-top: 11px;
    margin-bottom: 1em;
    font-size: 20px;
    font-weight: 400;
    color: #C5CAD5;
    line-height: 28px;
    text-align: center;
`

const ActionButton = styled(ButtonPrimary)`
   padding: 8px;
   width: 480px;
   height: 49px;
`

const TweetForm = styled.div`
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 17px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
`
const InputWrapper = styled.div`
  padding-top: 18px;
  margin-bottom: 18px;
`

const InputContainer = styled.div`
   border-bottom: 1px solid #858B9C;
`

const ErrorHint = styled.div`
  margin-top: 4px;
  color: red;
  font-size: 12px;
  line-height: 14px;
  height: 14px;
`
const TweetInput = styled.input<{ error?: boolean; fontSize?: string; align?: string }>`
      color: #41485D;
      width: 0;
      line-height: 40px;
      position: relative;
      font-weight: 500;
      outline: none;
      border: none;
      flex: 1 1 auto;
      width: 100%;
      font-size: 14px;
      //background-color:
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px;
      -webkit-appearance: textfield;
      ::-webkit-search-decoration {
          -webkit-appearance: none;
      }
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
          -webkit-appearance: none;
      }
      ::placeholder {
        color: #E2E4EA;
      }
`

const SubmitLoader = styled.div`
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
    }
    div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
    }
    div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
    }
    div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
    0% {
    transform: scale(0);
    }
    100% {
    transform: scale(1);
    }
    }
    @keyframes lds-ellipsis3 {
    0% {
    transform: scale(1);
    }
    100% {
    transform: scale(0);
    }
    }
    @keyframes lds-ellipsis2 {
    0% {
    transform: translate(0, 0);
    }
    100% {
    transform: translate(24px, 0);
    }

`

const CsmImage = styled.img`
  margin-top: 45px;
  align-self: center;
  width: 64px;
  height: 62px;
`

const RewardTitle = styled.div`
  margin-top: 15px;
  align-self: center;
  font-size: 32px;
  color: #111A34;
  line-height: 32px;
`

const RewardDetails = styled.div`
   font-size: 24px;
   align-self: center;
   color: #111A34;
   line-height: 16px;
   margin-top: 6px;
   margin-bottom: 31px;
`
const ClaimErrorHint = styled.div`
  align-self: center;
  margin-bottom: 16px;
  color: red;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
`
const referenceTweetUrl = 'https://twitter.com/CrustNetwork/status/1335859176916971525'
const hashTags = 'Crust,CSM,CrustNetwork'
const textTemplate = (address: string) => {
    return `Requesting CSM funds into ${address} on the Crust Storage Market event!`
}

function composeTweetLink(address: string) {
    const text = textTemplate(address)
    const data = {
        text,
        hashtags: hashTags,
        related: 'CrustNewtowrk',
        url: referenceTweetUrl,
    }
    const query = queryString.stringify(data)
    return `https://twitter.com/intent/tweet?${query}`
}


const tweetRegex = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)(\?.*)?$/

function extractTweetId(text: string | null): string {
    if (!text || text.length === 0 ) {
        return ''
    }
    const match = text.match(tweetRegex)
    if (match && match[3].length >0 ) {
        return match[3]
    }
    return ''
}


function iconFromToast(toast: ToastType) {
    switch(toast.level) {
        case 'warning': return warnIcon
        case 'error': return errorIcon
        case 'info': return infoIcon
        case 'success': return successIcon
    }
}

type SubmitResponse = {
    success?: boolean;
    type: 'userIdClaimed' | 'tweetNotQuoted' ;
    message: string;
}

function convertResponseToToast(data: SubmitResponse, errorMap: MessagesType): ToastType {
    if (data.success) {
        return errorMap.success
    }

    if (errorMap[data.type]) {
        return errorMap[data.type]
    }

    return errorMap.unknownError
}

async function submitTweet(tweetId: string, errorMap: MessagesType): Promise<ToastType> {
    try {
        const result = await axios.post('https://csm-api.crust.run/csm', {
            tweetId: tweetId,
        })
        if (result && result.data) {
            return convertResponseToToast(result.data as SubmitResponse, errorMap)
        }
    } catch(ex) {
        if (ex && ex.response && ex.response.data) {
            return convertResponseToToast(ex.response.data as SubmitResponse, errorMap)
        }
        if (ex && !ex.response) {
            return errorMap.networkError
        }
    }

    return errorMap.unknownError
}

const minCruDeposit = '1000000000000000000'
const zeroAddress = '0x0000000000000000000000000000000000000000'

export default function CruFaucet(props: RouteComponentProps<{ ns: string }>) {
    const {
        match: {
            params: { ns }
        }
    } = props
    const { account, } = useActiveWeb3React()
    const { lang, messages } = useLang(ns)
    const [tweetId, setTweetId] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [ toast, setToast ] = useState(null as ToastType | null)
    const [invalidUrl, setInvalidUrl] = useState(false)
    const [, setTweetUrl] = useDebouncedChangeHandler('', (newValue) => {
        const id = extractTweetId(newValue)
        setTweetId(id)
        setInvalidUrl(!!newValue && !id)
    })

    const cruContract = useCruContract()
    const claimContract = useClaimContract()

    const balance: BigNumber = useSingleCallResult(cruContract, 'balanceOf', [account || zeroAddress] )?.result?.[0]
    const pendingClaim: boolean = useSingleCallResult(claimContract, '_pendingClaimAddress', [account || zeroAddress] )?.result?.[0]
    const claimed: boolean = useSingleCallResult(claimContract, '_claimedAddresses', [account || zeroAddress] )?.result?.[0]
    const insuffienctBalance = balance && balance.lt(minCruDeposit)

    console.log('pending ', pendingClaim, 'claimed ', claimed)

    const doSubmit = async () => {
        if(!tweetId || submitting) {
            return
        }
        setSubmitting(true)
        const result = await submitTweet(tweetId, messages)
        setSubmitting(false)
        setToast(result)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setToast(null)
        }, 3000)
        return () => {
            clearTimeout(timer)
        }
    }, [toast])

    const openTweetWindow = () => {
        const tweetUrl = composeTweetLink(account || '0x0000000000000000000000000000000000000000')
        if (!window.open(tweetUrl)) {
            setToast({
                level: 'warning',
                message: 'Error opening browser window, please check your browser settings.',
            })
        }
    }

    const canClaim = account && pendingClaim && !insuffienctBalance && !claimed
    let claimErrorText = ''
    if (!canClaim) {
        if (!account) {
            claimErrorText = lang.connectWallet
        }  else if(claimed) {
            claimErrorText = lang.alreadyClaimed
        } else if (!pendingClaim) {
            claimErrorText = lang.notInList
        } else if (insuffienctBalance) {
            claimErrorText = lang.insuffientBalance
        }
   }

    const [inClaim, setInClaim] = useState(false)
    const loadingClaimInfo = inClaim || (account && pendingClaim === undefined && claimed === undefined)
    const { csmClaimCallback } = useCsmClaimCallback(account)

    const doClaim = async () => {
        if (loadingClaimInfo) {
            return
        }
        setInClaim(true)
        try {
           await csmClaimCallback()
        } finally {
            setInClaim(false)
        }
    }

    return <Container>
        <BgLeft></BgLeft>
        <BgRight></BgRight>
        <Content>
            <ErrorMessage visible={ !!toast }>
                { toast && <MessageIcon src={iconFromToast(toast)}></MessageIcon>}
                { toast && toast.message}
            </ErrorMessage>
            <StepPreface>
                <StepDesc>{lang.preface}</StepDesc>
            </StepPreface>
            <Step>
                <StepTitle>{lang.step1}</StepTitle>
                <StepDesc>{lang.step1Desc}</StepDesc>
                <ActionButton onClick={() => openTweetWindow() }>
                    {lang.tweetText}
                </ActionButton>
            </Step>
            <Step>
                <StepTitle>{lang.step2}</StepTitle>
                <StepDesc>
                    {lang.step2Desc}
                </StepDesc>
                <TweetForm>
                    <InputWrapper>
                        <InputContainer>
                            <TweetInput placeholder={lang.tweetPlaceholder} onChange={(event) => setTweetUrl(event.currentTarget.value)} />
                        </InputContainer>
                        <ErrorHint>{ invalidUrl && lang.tipInvalidUrl }</ErrorHint>
                    </InputWrapper>
                    <ActionButton disabled={!submitting && !tweetId} onClick={() => doSubmit()}>
                        { submitting && <SubmitLoader>
                            <div></div><div></div><div></div><div></div>
                        </SubmitLoader> }
                        { !submitting && lang.submit }
                    </ActionButton>
                </TweetForm>
            </Step>
            <Step>
                <StepTitle>{lang.step3}</StepTitle>
                <StepDesc>
                    {lang.step3Desc}
                </StepDesc>
                <TweetForm>
                    <CsmImage src={csmLogo}></CsmImage>
                    <RewardTitle> </RewardTitle>
                    <RewardDetails>{lang.rewardDetails}</RewardDetails>
                    <ClaimErrorHint>{ (!loadingClaimInfo && claimErrorText) && claimErrorText }</ClaimErrorHint>
                    <ActionButton disabled={!canClaim} onClick={() => doClaim()}>
                    { loadingClaimInfo && <SubmitLoader>
                            <div></div><div></div><div></div><div></div>
                        </SubmitLoader> }
                        { !loadingClaimInfo && lang.claimReward}
                    </ActionButton>
                </TweetForm>
            </Step>
        </Content>
    </Container>
}
