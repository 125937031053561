import React, { useRef } from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'
import i18n from '../../i18n';

const StyledMenu = styled.div`
  /* margin-left: 0.5rem; */
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 16rem;
  background-color: white;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: -2rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 2rem;
    left: 0;
  `};
`

const MenuItem = styled.div`
  flex: 1;
  font-family: nasalization;
  padding: 0.5rem 1.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    background-color: #f8f9fa;
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
  :first-child {
    margin-top: 0.5rem;
  }
  :last-child {
    margin-bottom: 0.5rem;
  }
`

const CruSpan = styled.span`
  font-family: nasalization;
  color: rgba(255,255,255, 0.5);
  cursor: pointer;
  white-space: nowrap;
  word-break: keep-all;
  margin-right: 50px;
  &:hover {
    color: #eb761c;
  }
`

interface LangItem {
    key: string,
    name: string,
}
const en: LangItem = { key: 'en', name: 'EN' }
const zhCN: LangItem = { key: 'zh-CN', name: '中文' }
const langs: LangItem[] = [en, zhCN]

export default function Language() {
    const node = useRef<HTMLDivElement>()
    const open = useModalOpen(ApplicationModal.LANGUAGE)
    const toggle = useToggleModal(ApplicationModal.LANGUAGE)
    useOnClickOutside(node, open ? toggle : undefined)
    const cLang = langs.find((l) => l.key === i18n.language) ?? en
    const onSelect = (lang: LangItem) => {
        i18n.changeLanguage(lang.key)
        toggle()
    }
    return (
        // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
        <StyledMenu ref={node as any}>
            <CruSpan onClick={toggle}>{`${cLang.name} ▼`}</CruSpan>
            {
                open && (<MenuFlyout>
                    {langs.map((lang, index) =>
                        <MenuItem key={`${index}_langs`}
                            id="langs"
                            children={lang.name}
                            onClick={() => onSelect(lang)} />
                    )}
                </MenuFlyout>)
            }
        </StyledMenu>
    )
}
