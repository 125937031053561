import { JSBI, Pair } from '@uniswap/sdk'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary } from '../../components/Button'

import { useColor } from '../../hooks/useColor'

import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { RowBetween, RowFixed } from '../../components/Row'
import { Dots } from '../../components/swap/styleds'
import { useWalletModalToggle } from '../../state/application/hooks'
import AddLiquidityModal from './AddLiquidityModal'
import RemoveLiquidityModal from './RemoveLiquidityModal'
import { TYPE } from '../../theme'

export const FixedHeightRow = styled(RowBetween)`
  height: 15px;
`

const AdaptiveRowBetween = styled(RowBetween)`
  margin-top: 10px;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`

const ButtonWrap = styled(ButtonPrimary)`
  margin-left: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 12px;
    margin-left: 0;
  `};
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
}

export default function AddLiquidityListItem({ pair, border }: PositionCardProps) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const [token0Total, token1Total] = [pair.reserve0, pair.reserve1]
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
      ] : [undefined, undefined]

  const shareExist = account && token0Deposited && token1Deposited
  const backgroundColor = useColor(pair?.token0)
  const [showAddLiquidityModal, setShowAddLiquidityModal] = useState(false)
  const toggleWalletModal = useWalletModalToggle()
  const handleAddLiquidityClick = useCallback(() => {
    if (account) {
      setShowAddLiquidityModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const [showRemoveLiquidityModal, setShowRemoveLiquidityModal] = useState(false)
  const handleRemoveLiquidityClick = useCallback(() => {
    if (account) {
      setShowRemoveLiquidityModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const toMax11 = (num: string) => {
    let arr = num.split(".")
    if (arr.length !== 2 || arr[1].length <= 11) {
      return num;
    }

    return `${arr[0]}.${arr[1].substring(0, 11)}`
  }
  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <AddLiquidityModal
        isOpen={showAddLiquidityModal}
        onDismiss={() => setShowAddLiquidityModal(false)}
        currency0={currency0}
        currency1={currency1}
      />
      <RemoveLiquidityModal
        isOpen={showRemoveLiquidityModal}
        onDismiss={() => setShowRemoveLiquidityModal(false)}
        currency0={currency0}
        currency1={currency1}
      />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
            <Text fontWeight={500} fontSize={20}>
              {!currency0 || !currency1 ? <Dots>{t('loading')}</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>
        </FixedHeightRow>
        <AutoColumn gap="8px">
          <FixedHeightRow>
            <RowFixed>
              <Text fontSize={14} color="#858b9c">
                {t('pooled')} {currency0.symbol}:
              </Text>
            </RowFixed>
            {token0Total ? (
              <RowFixed>
                <Text fontSize={14} marginLeft={'6px'}>
                  <TYPE.main>{toMax11(token0Total?.toSignificant(6))}</TYPE.main>
                </Text>
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>

          <FixedHeightRow>
            <RowFixed>
              <Text fontSize={14} color="#858b9c">
                {t('pooled')} {currency1.symbol}:
              </Text>
            </RowFixed>
            {token1Total ? (
              <RowFixed>
                <Text fontSize={14} marginLeft={'6px'}>
                  <TYPE.main>{toMax11(token1Total?.toSignificant(6))}</TYPE.main>
                </Text>
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>

          <AdaptiveRowBetween>
            <ButtonPrimary
              padding="8px"
              onClick={handleAddLiquidityClick}
            >
              {t('addLiquidity')}
            </ButtonPrimary>
            <ButtonWrap
              padding="8px"
              onClick={handleRemoveLiquidityClick}
              disabled={!shareExist}
            >
              {t('removeLiquidity')}
            </ButtonWrap>
          </AdaptiveRowBetween>
        </AutoColumn>
      </AutoColumn>
    </StyledPositionCard>
  )
}
