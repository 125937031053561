import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'

import { JSBI, TokenAmount, ETHER } from '@uniswap/sdk'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { FixedHeightRow, RowBetween, RowFixed, SecendItem } from '../../components/Row'
import { ButtonPrimary } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'
import { Text } from 'rebass'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  display: inline-block;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  margin-top: 20px;
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const DataRow = styled(RowBetween)`
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`

const CardContainer = styled.div`
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

const FixedHeightRowPaddingSm = styled(FixedHeightRow)`
  margin-top: 10px;
`

const FixedHeightRowPaddingMd = styled(FixedHeightRow)`
  margin-top: 15px;
`

const ButtonWrap = styled(ButtonPrimary)`
  margin-left: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 12px;
    margin-left: 0;
  `};
`

interface WithdrawProps {
  currencyIdA: string
  currencyIdB: string
}
export default function Withdraw({ currencyIdA, currencyIdB }: WithdrawProps) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfo = useStakingInfo(stakingTokenPair)?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = false

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const WETH = currencyA === ETHER ? tokenA : tokenB

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <CardContainer>
        <FixedHeightRow>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
            <Text fontWeight={500} fontSize={20} marginLeft="15px">
              {currencyA?.symbol}-{currencyB?.symbol}
            </Text>
          </RowFixed>
        </FixedHeightRow>

        <FixedHeightRowPaddingMd>
          <RowFixed>
            <Text fontSize={14} color="#858b9c">
              {t('totalDeposits')}
            </Text>
          </RowFixed>
          <SecendItem>
            <Text fontSize={14}>
              <TYPE.main>
                {valueOfTotalStakedAmountInUSDC
                  ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                  : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ETH`}
              </TYPE.main>
            </Text>
          </SecendItem>
        </FixedHeightRowPaddingMd>

        <FixedHeightRowPaddingSm>
          <RowFixed>
            <Text fontSize={14} color="#858b9c">
              {t('poolRate')}
            </Text>
          </RowFixed>
          <SecendItem>
            <Text fontSize={14}>
              <TYPE.main>
                {stakingInfo?.totalRewardRate
                  ?.multiply((60 * 60 * 24 * 7).toString())
                  ?.toFixed(0, { groupSeparator: ',' }) ?? '-'}
                {' CSM / week'}
              </TYPE.main>
            </Text>
          </SecendItem>
        </FixedHeightRowPaddingSm>
      </CardContainer>

      <CardContainer>
        <FixedHeightRowPaddingSm>
          <RowFixed>
            <Text fontSize={14} color="#858b9c">
              {t('myLpTokenAvailable')}
            </Text>
          </RowFixed>
          <SecendItem>
            <Text fontSize={14}>
              {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
                <TYPE.main>{userLiquidityUnstaked.toSignificant(6)} {currencyA?.symbol}-{currencyB?.symbol}</TYPE.main>
              )}
            </Text>
          </SecendItem>
        </FixedHeightRowPaddingSm>

        <FixedHeightRowPaddingSm>
          <RowFixed>
            <Text fontSize={14} color="#858b9c">
              {t('myLpTokenDeposit')}
            </Text>
          </RowFixed>
          <SecendItem>
            <Text fontSize={14}>
              <TYPE.main>{stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'} {currencyA?.symbol}-{currencyB?.symbol}</TYPE.main>
            </Text>
          </SecendItem>
        </FixedHeightRowPaddingSm>

        <FixedHeightRowPaddingSm>
          <RowFixed>
            <Text fontSize={14} color="#858b9c">
              {t('myUnclaimedCSM')}
            </Text>
          </RowFixed>
          <SecendItem>
            <Text fontSize={14}>
              <TYPE.main>
                <CountUp
                  key={countUpAmount}
                  isCounting
                  decimalPlaces={4}
                  start={parseFloat(countUpAmountPrevious)}
                  end={parseFloat(countUpAmount)}
                  thousandsSeparator={','}
                  duration={1}
                />
              </TYPE.main>
            </Text>
          </SecendItem>
        </FixedHeightRowPaddingSm>

        <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
          {!showAddLiquidityButton && (
            <DataRow>
              <ButtonPrimary padding="8px" borderRadius="8px" width="100%" onClick={handleDepositClick} disabled={true}>
                {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? t('deposit') : t('depositLpToken')}
              </ButtonPrimary>

              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                <>
                  <ButtonWrap
                    padding="8px"
                    borderRadius="8px"
                    width="100%"
                    onClick={() => setShowUnstakingModal(true)}
                  >
                    {t('withdrawAndClaim')}
                  </ButtonWrap>
                </>
              )}
              {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                <ButtonWrap
                  padding="8px"
                  borderRadius="8px"
                  width="100%"
                  onClick={() => setShowClaimRewardModal(true)}
                >
                  {t('claimCSM')}
                </ButtonWrap>
              )}
            </DataRow>
          )}
        </PositionInfo>
      </CardContainer>

      {stakingInfo && (
        <>
          <StakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <UnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}
    </PageWrapper>
  )
}
