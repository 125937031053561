import React from 'react'
import { Text } from 'rebass'
import { ChainId, Currency, currencyEquals, ETHER, Token } from '@uniswap/sdk'
import styled from 'styled-components'

import { CRU, CSM, SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { useTranslation } from 'react-i18next'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.bg3)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.bg2};
  }

  background-color: ${({ theme, disable }) => disable && theme.bg3};
  opacity: ${({ disable }) => disable && '0.4'};
`

function ItemCurrency(
  { token, selected, onClick }:
    {
      token: Token | Currency,
      selected: boolean,
      onClick: () => void,
    }) {
  return (
    <BaseWrapper onClick={onClick} disable={selected} >
      <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
      <Text fontWeight={500} fontSize={16}>
        {token.symbol}
      </Text>
    </BaseWrapper>
  )
}

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  otherCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  otherCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  const cru = chainId ? CRU[chainId] : null
  const csm = chainId ? CSM[chainId] : null
  let currences: Currency[] = []

  // const currences: Currency[] = _.filter(chainId ? [cru, csm, ETHER, ...SUGGESTED_BASES[chainId]] : [])
  if (cru)currences = currences.concat(cru)
  if (csm)currences = currences.concat(csm)
  if (chainId) currences = currences.concat(ETHER).concat(SUGGESTED_BASES[chainId])
  return (
    <AutoColumn gap="md">
      <AutoRow >
        <Text fontWeight={500} fontSize={14}>
          {t('commonBases')}
        </Text>
        <QuestionHelper text={t('commonBasesTip')} />
      </AutoRow>
      <AutoRow gap="4px">
        {currences.map((token: Currency, index: number) => {
          if (token) {
            const selected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, token))
            const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, token))
            
            return (
              <ItemCurrency
                onClick={() => !selected && !otherSelected && onSelect(token)}
                selected={selected || otherSelected}
                token={token}
                key={`item-currency-${index}`}
              />
            )
          }
          return null
        })}
      </AutoRow>
    </AutoColumn>
  )
}
