

import { getMessages } from './messages'
import i18n from '../../i18n';

const langCn = {
  preface: '活动将从12月8号 14：00 GMT开始，持续一周时间。您只需要按照下方提示流程进行即可获得1000个CSM空投，您可以在12月15日活动结束后完成第三步以领取奖励。',
  step1: '第一步',
  step1Desc: "点击按钮发推并在推文中填写ETH地址(其他文字不影响)。",
  tweetText: '发推获取空投',
  step2: '第二步',
  step2Desc: '把第一步中推文的链接粘贴到下方的地址框中后点击提交。',
  tweetPlaceholder: '把推文地址粘贴到这里',
  tipInvalidUrl: '输入的不是推文地址。',
  submit: '提交',
  step3: '第三步',
  step3Desc: '您可以在活动结束后，点击"领取"按钮获得CSM奖励。您的账户至少要有1CRU才能领取成功。',
  rewardTitle: 'CSM 奖励',
  rewardDetails: '现在领取你的CSM奖励。',
  claimReward: '领取我的CSM奖励',
  connectWallet: '请先连接钱包',
  notInList: '此地址不满足CSM领取条件。',
  insuffientBalance: '当前地址中没有足够的CRU余额',
  alreadyClaimed: '此地址已经领取过奖励',
}

const langEn = {
  preface: 'The event starts on Dec 8th at 14:00 GMT and lasts for 1 week. Follow steps below to get 1000 CSM. You can claim the tokens by completing step 3 on Dec 15th.',
  step1: 'Step 1',
  step1Desc: "Click button to make a tweet with your Ethereum address pasted into the contents (surrounding text does't matter).",
  tweetText: 'Tweet to Trigger an Airdrop',
  step2: 'Step 2',
  step2Desc: 'Copy-paste the tweets URL into the below input box and submit.',
  tweetPlaceholder: 'Paste Your Tweet URL here',
  tipInvalidUrl: 'Please enter a valid tweet url.',
  submit: 'Submit',
  step3: 'Step 3',
  step3Desc: 'Click Claim button to get your CSM reword at the end of this event. You need to have at least 1 CRU in your account to make the claim success.',
  rewardTitle: 'CSM Reward',
  rewardDetails: 'Claim your CSM reward now.',
  claimReward: 'Claim my CSM Reward',
  connectWallet: 'Please connect to wallet first',
  notInList: 'This account is not qualified to claim CSM reward.',
  insuffientBalance: 'Insufficent CRU balance in this account',
  alreadyClaimed: 'This account has already claimed',
}

export function useLang(ns: string) {
  const lang: any = ns === 'auto' ? i18n.language : ns
  const messages = getMessages(lang)
  if (lang === 'zh-CN') {
    return {
      lang: langCn,
      messages,
    }
  }

  return {
    lang: langEn,
    messages,
  }
}

