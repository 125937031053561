import * as _ from 'lodash'

export type ToastType = {
  level: 'warning' | 'error' | 'info' | 'success';
  message: string;
}

export const errorMap = {
  success: {
    level: 'success',
    message: 'Submit success!',
    messageCn: '提交成功!'
  },
  networkError: {
    level: 'error',
    message: 'Network request error, please retry later.',
    messageCn: '网络请求失败, 请稍后再试',
  },
  unknownError: {
    level: 'error',
    message: 'Something bad happended... Please try again later',
    messageCn: '出错了... 请稍后再试.',
  },
  tweetIdAlreadyClaimed: {
    level: 'error',
    message: 'This tweet is already registered.',
    messageCn: '此推文已经注册过活动',
  },
  userIdClaimed: {
    level: 'error',
    message: 'This tweet is already registered.',
    messageCn: '此推文已经注册过活动',
  },
  addressClaimed: {
    level: 'error',
    message: 'This eth address is already registered.',
    messageCn: '此eth账号已经注册过活动',
  },
  tweetNotQuoted: {
    level: 'error',
    message: 'The tweet content is not valid, please use the Tweet button to send a tweet.',
    messageCn: '推文内容格式不对, 请使用第一步的发推按钮发送推文',
  },
  noAddressFound: {
    level: 'error',
    message: 'No eth address in the tweet, please use the Tweet button to send a tweet.',
    messageCn: '推文中没有包含ETH地址, 请使用第一步的发推按钮发送推文',
  },
  multiAddresses: {
    level: 'error',
    message: 'More than eth address in the tweet, please use the Tweet button to send a tweet.',
    messageCn: '推文中包含多个ETH地址, 请使用第一步的发推按钮发送推文',
  },
  tweetNotExist: {
    level: 'error',
    message: 'The tweet does not exist, please use the Tweet button to send a tweet.',
    messageCn: '填写的推文不存在, 请使用第一步的发推按钮发送推文',
  },
  tweetIdNotMatch: {
    level: 'error',
    message: 'The tweet content is not valid, please use the Tweet button to send a tweet.',
    messageCn: '推文内容格式不对, 请使用第一步的发推按钮发送推文',
  },
  invalidTweetContent: {
    level: 'error',
    message: 'The tweet content is not valid, please use the Tweet button to send a tweet.',
    messageCn: '推文内容格式不对, 请使用第一步的发推按钮发送推文',
  },
  invalidTweet: {
    level: 'error',
    message: 'The tweet content is not valid, please use the Tweet button to send a tweet.',
    messageCn: '推文内容格式不对, 请使用第一步的发推按钮发送推文',
  },
  tweetIdInUse: {
    level: 'warning',
    message: 'Slow down, server is busy now.',
    messageCn: '慢一点, 正在处理.',
  },
  userIdInUse: {
    level: 'warning',
    message: 'Slow down, server is busy now.',
    messageCn: '慢一点, 正在处理.',
  },
  registerDisabled: {
    level: 'error',
    message: 'Register period is ended.',
    messageCn: '活动已经结束注册.',
  },
  invalidTweetId: {
    level: 'error',
    message: 'Tweet id is invalid!',
    messageCn: '非法的推文链接!',
  }
}

export type MessagesType = { [key: string]: ToastType }

export function getMessages(lang: string): MessagesType {
  const messages = _.mapValues(errorMap, (o) => {
    return {
      level: o.level,
      message: lang === 'zh-CN' ? o.messageCn : o.message,
    } as ToastType
  })
  return messages
}
