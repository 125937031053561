import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n';
import AddLiquidityListItem from './AddLiquidity'
import ManageLiquidity from './ManageLiquidity'
import Withdraw from './Withdraw.tsx'
import { Currency } from '@uniswap/sdk'
import { currencyId } from '../../utils/currencyId'
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom'
import Collapse from "@kunukn/react-collapse"
import { parse } from 'query-string';
const Container = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 56px;
  /* @media only screen and (max-width: 720px) {
      margin-top: 15px;
  } */
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
  `}
`

const Title = styled.div`
  color: #fa8c16;
  text-align: center;
  width: 100%;
  font-size: 26px;
  margin-top: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0;
  `}
`

const Description = styled.div`
  color: #c5cad5;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  line-height: 35px;
`

const PanelContainer = styled.div`
  margin-top: 25px;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0px;
  `}

`

const StepTitle = styled.div`
  color: #c5cad5;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  white-space: break-spaces;
`

const ActionContent = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
`

const ActionLink = styled.a`
   color: #fa8c16;
   text-decoration: none;
`
const BackWrap = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  margin-top: -2rem;
  width: 100%;
  max-width: 640px;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 0;
    padding: 1rem 0.2rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: -2rem;
    padding: 1rem 0.2rem;
  `}
  
`
const BackButton = styled.div`
  color : ${({ theme }) => theme.text3};
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
`
// https://wiki.crust.network/docs/en/DSM
// https://wiki.crust.network/docs/zh-CN/DSM

function CsmStorageMarketLink(props: { lang: 'en' | 'zh-CN' }) {
  const { lang } = props
  const href = `https://wiki.crust.network/docs/${lang}/DSM`
  if (lang === 'zh-CN') {
    return <ActionContent>
      CSM (Crust Storage Market) 是<ActionLink target="_blank" href={href}>Crust去中心化存储市场</ActionLink>通证。
  </ActionContent>
  }
  return <ActionContent>
    CSM (Crust Storage Market) token is designed for <ActionLink target="_blank" href={href}>Crust Decentralized Storage Market</ActionLink>.
  </ActionContent>
}

function ReadMoreButton(props: { lang: 'en' | 'zh-CN'; }) {
  const { lang, } = props;
  if (lang === 'zh-CN') {
    return (
      <ActionContent>
        <ActionLink target="_blank" href="https://mp.weixin.qq.com/s/fhaYz8gEpp8egCcDro6-IQ">阅读Crust通证相关指标</ActionLink> 获取更多信息。
      </ActionContent>)
  }
  return (
    <ActionContent>
      <ActionLink target="_blank" href="https://medium.com/crustnetwork/crust-token-metrics-economics-84592efc6d1f">Read more</ActionLink> on CSM and storage market.
    </ActionContent>)
}

function ReTweetButton(props: { lang: 'en' | 'zh-CN'; isAuto: boolean; }) {
  const { lang, isAuto } = props;
  const langStr = isAuto ? 'auto' : lang;
  const link = `#/csm-faucet/${langStr}`
  if (lang === 'zh-CN') {
    return (
      <ActionContent>
        <ActionLink href={link}>转推</ActionLink> 获取更多CSM。
      </ActionContent>)
  }
  return (
    <ActionContent>
      <ActionLink href={link}>Retweet</ActionLink> to get more CSM.
    </ActionContent>)
}

const supportStep = ['step1', 'step2', 'step3', 'info'];
export default function Crust(props: RouteComponentProps<{ stepStr: string }>) {
  const {
    match: {
      params: { stepStr }
    },
    location
  } = props

  const { t } = useTranslation()
  const { idA, idB } = useMemo(() => parse(location.search), [location.search])
  const currencyIdA = (idA ?? '') as string
  const currencyIdB = (idB ?? '') as string
  const step = stepStr ?? 'step1'
  // const [csmStep, setCSMStep] = useState(1)
  // const [currencyIdA, setCurrencyIdA] = useState((idA ?? '') as string )
  // const [currencyIdB, setCurrencyIdB] = useState((idB ?? '') as string )
  const history = useHistory()
  const manageClick = (currency0: Currency, currency1: Currency) => {
    let currencyA = currencyId(currency0)
    let currencyB = currencyId(currency1)
    // setCurrencyIdA(currencyA)
    // setCurrencyIdB(currencyB)
    history.push(`/cru/step3?idA=${currencyA}&idB=${currencyB}`)
  }

  const lang = i18n.language
  const langCode = lang === 'zh-CN' ? 'zh-CN' : 'en'

  if (!supportStep.find(s => s === stepStr)) {
    return <Redirect to={{ ...location, pathname: '/cru/step2' }} />
  }
  return (
    <Container>
      <Content>
        {step === 'info' && (<Title>{t('activity')}</Title>)}
        {step === 'info' && (<Collapse isOpen={true} collapseHeight='90px'>
          <Description>
            <CsmStorageMarketLink lang={langCode} />
            {t('activityDesc')}
            <ReadMoreButton lang={langCode}></ReadMoreButton>
            <ReTweetButton lang={langCode} isAuto={true}></ReTweetButton>
          </Description>
        </Collapse>)}
        {
          step === 'step3' && <BackWrap>
            <BackButton onClick={() => history.replace('/cru/step2')}> {`< ${t('back')}`}</BackButton>
          </BackWrap>

        }
        <PanelContainer>

          {
            step === 'step1' && (<React.Fragment>
              <StepTitle>{t('step1')}</StepTitle>
              <AddLiquidityListItem />
            </React.Fragment>)
          }
          {
            step === 'step2' && (<React.Fragment>
              <StepTitle>{t('step3')}</StepTitle>
              <ManageLiquidity manageClick={manageClick} />
            </React.Fragment>)
          }
          {
            step === 'step3' && (<React.Fragment>
              <StepTitle>{t('step3')}</StepTitle>
              <Withdraw currencyIdA={currencyIdA} currencyIdB={currencyIdB} />
            </React.Fragment>)
          }
        </PanelContainer>
      </Content>
    </Container>
  )
}
