import { Currency } from '@uniswap/sdk'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Column from '../../components/Column'
import Modal from '../../components/Modal'
import { RowBetween } from '../../components/Row'
import { CloseIcon, TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import RemoveLiquidityModalContent from './RemoveLiquidityModalContent'

const ContentWrapper = styled(Column)`
  width: 100%;
  padding: 1rem;
`

interface RemoveLiquidityModalProps {
  isOpen: boolean
  onDismiss: () => void
  currency0: Currency
  currency1: Currency
}

export default function RemoveLiquidityModal({ isOpen, onDismiss, currency0, currency1 }: RemoveLiquidityModalProps) {
  const { t } = useTranslation()
  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      <ContentWrapper>
        <RowBetween>
          <TYPE.body>{t('removeLiquidity')}</TYPE.body>
          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <RemoveLiquidityModalContent currencyIdA={currencyId(currency0)} currencyIdB={currencyId(currency1)} />
      </ContentWrapper>
    </Modal>
  )
}
