import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/Modal'
import Column from '../../components/Column'
import styled from 'styled-components'
import { RowBetween } from '../../components/Row'
import { TYPE, CloseIcon } from '../../theme'
import { Currency } from '@uniswap/sdk'
import AddLiquidityModalContent from './AddLiquidityModalContent'
import { currencyId } from '../../utils/currencyId'

const ContentWrapper = styled(Column)`
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
`
interface AddLiquidityModalProps {
  isOpen: boolean
  onDismiss: () => void
  currency0: Currency
  currency1: Currency
}

export default function AddLiquidityModal({ isOpen, onDismiss, currency0, currency1 }: AddLiquidityModalProps) {
  const { t } = useTranslation()

  const wrappedOnDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      <ContentWrapper>
        <RowBetween>
          <TYPE.body>{t('addLiquidity')}</TYPE.body>
          <CloseIcon onClick={wrappedOnDismiss} />
        </RowBetween>
        <AddLiquidityModalContent currencyIdA={currencyId(currency0)} currencyIdB={currencyId(currency1)} />
      </ContentWrapper>
    </Modal>
  )
}
