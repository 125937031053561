import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
// @ts-ignore-next-line
import detectBrowserLanguage from 'detect-browser-language'

const lang = detectBrowserLanguage() ?? 'en'
console.info('llllll', lang)
i18next
  .use(XHR)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `./locales/{{lng}}.json`
    },
    react: {
      useSuspense: true
    },
    lng: lang,
    fallbackLng: 'en',
    preload: ['en','zh-CN'],
    keySeparator: false,
    interpolation: { escapeValue: false }
  })

export default i18next
