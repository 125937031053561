import { Pair, JSBI, Percent } from '@uniswap/sdk'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import styled from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary } from '../../components/Button'

import { useColor } from '../../hooks/useColor'

import { LightCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { FixedHeightRow, RowBetween, RowFixed, SecendItem } from '../../components/Row'
import { useWalletModalToggle } from '../../state/application/hooks'
import { StakingInfo, useStakingInfo } from '../../state/stake/hooks'
import { useTotalSupply } from '../../data/TotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { Dots } from '../../components/swap/styleds'
import { TYPE } from '../../theme'

const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`

interface PositionCardProps {
  pair: Pair
  border?: string
  manageClick: Function
}

interface LiquidityStakingProps {
  stakingInfo: StakingInfo
  pair: Pair
}

function LiquidityStakingInfo({ stakingInfo, pair }: LiquidityStakingProps) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  return (
    <React.Fragment>
      <FixedHeightRow>
        <RowFixed>
          <Text fontSize={14} color="#858b9c">
            {t('yourPoolShare2')}
          </Text>
        </RowFixed>
        <SecendItem>
          <Text fontSize={14} color="#41485D" marginLeft={'6px'}>
            {poolTokenPercentage ? poolTokenPercentage.toFixed(2) + '%' : '-'}
          </Text>
        </SecendItem>
      </FixedHeightRow>

      <FixedHeightRow>
        <RowFixed>
          <Text fontSize={14} color="#858b9c">
            {t('poolRate')}
          </Text>
        </RowFixed>
        <SecendItem>
          <Text fontSize={14} color="#41485D" marginLeft={'6px'}>
            {`${stakingInfo.totalRewardRate
              ?.multiply(`${60 * 60 * 24 * 7}`)
              ?.toFixed(0, { groupSeparator: ',' })} CSM / week`}
          </Text>
        </SecendItem>
      </FixedHeightRow>
    </React.Fragment>
  )
}

export default function ManageLiquidityListItem({ pair, border, manageClick }: PositionCardProps) {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const [token0Total, token1Total] = [pair.reserve0, pair.reserve1]

  const backgroundColor = useColor(pair?.token0)
  const toggleWalletModal = useWalletModalToggle()
  const stakingInfo = useStakingInfo(pair)
  const handleManageLiquidityClick = useCallback(() => {
    if (account) {
      manageClick(currency0, currency1)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal, manageClick, currency0, currency1])
  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
            <Text fontWeight={500} fontSize={20}>
              {!currency0 || !currency1 ? <Dots>{t('loading')}</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>
        </FixedHeightRow>
        <AutoColumn gap="8px">
          {stakingInfo?.length > 0 ? (<LiquidityStakingInfo stakingInfo={stakingInfo[0]} pair={pair} />) : (
            <React.Fragment>
              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize={14} color="#858b9c">
                    {t('pooled')} {currency0.symbol}:
                  </Text>
                </RowFixed>
                {token0Total ? (
                  <SecendItem>
                    <Text fontSize={14} marginLeft={'6px'}>
                      <TYPE.main>{token0Total?.toSignificant(6)}</TYPE.main>
                    </Text>
                  </SecendItem>
                ) : (
                  '-'
                )}
              </FixedHeightRow>

              <FixedHeightRow>
                <RowFixed>
                  <Text fontSize={14} color="#858b9c">
                    {t('pooled')} {currency1.symbol}:
                  </Text>
                </RowFixed>
                {token1Total ? (
                  <SecendItem>
                    <Text fontSize={14} marginLeft={'6px'}>
                      <TYPE.main>{token1Total?.toSignificant(6)}</TYPE.main>
                    </Text>
                  </SecendItem>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </React.Fragment>)
          }

          <RowBetween marginTop="10px">
            <ButtonPrimary
              padding="8px"
              width="99%"
              onClick={handleManageLiquidityClick}
            >
              {t('manage')}
            </ButtonPrimary>
          </RowBetween>
        </AutoColumn>
      </AutoColumn>
    </StyledPositionCard>
  )
}
